import { observer } from "mobx-react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import Moment from "react-moment";
import {
  RxDashboard,
  RxEyeOpen,
  RxPencil1,
  RxPerson,
  RxPlus,
} from "react-icons/rx";
import {
  useBanUserStore,
  BanUserStore,
  BanUserStoreContext,
} from "./store/BanUserStore";
import Filter from "./BanUserFilter/banuserfilter";
import { MdAdd, MdReportGmailerrorred } from "react-icons/md";
import { useEffect, useState } from "react";
import Pagination from "react-js-pagination";
import {
  BiDetail,
  BiDollarCircle,
  BiSearch,
  BiInfoCircle,
} from "react-icons/bi";
import { useAuthStore } from "../Auth/store/Auth";
import { toast } from "react-toastify";
// const BanUserManagement = () => {
//   const store = new BanUserStore({
//     isloading: true,
//   });
//   return (
//     <BanUserStoreContext.Provider value={store}>
//       <Screen />
//     </BanUserStoreContext.Provider>
//   );
// };

// export default BanUserManagement;

const BanUserManagement = observer(() => {
  const navigate = useNavigate();
  const {Token} = useAuthStore();
  const [banid, setbanid] = useState("");
  const [emptyinput,setemptyinput] = useState(false);
  const [searchParams] = useSearchParams();
  const searchParamPage = searchParams.get("page");
  const [currentPage, setCurrentPage] = useState(searchParams.get("page") || 1);
  const {
    Ban,
    loadBan,
    nolist,
    setbanreason,
    ChangeBanStatus,
    banreason,
    setMessage,
    message,
    setReason,
    reason,
    activePage,
    perPage,
    Count,
    NofilterwithPagination,
    filtertrue,
    getBan,
    setActivePage,
    setIndexPage,
    filterVisibility,
    setToken,
    indexPage,
    UnBanStatus
  } = useBanUserStore();

  const handlePageChange = (pageNumber) => {
    NofilterwithPagination(true);
    const element = document.getElementById("tablescroll");
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
    // if (filtertrue) {
    //   getFilterUsers(pageNumber);
    //   setIndexPage(pageNumber);
    //   setActivePage(pageNumber);
    // } else {
      if (filterVisibility === true) {
        getBan(pageNumber);
        setActivePage(pageNumber);
        setIndexPage(pageNumber);
      } else {
        setActivePage(pageNumber);
        setIndexPage(pageNumber);
      }
    // }

    /**
     * if selected page is not 1 then navigate to the selected page adn ad dpageNumber to url
     */
    if (Number(pageNumber) + 1 !== 1) {
      navigate(`?page=${Number(pageNumber)}`);
      setActivePage(Number(pageNumber));
    } else {
      navigate("");
    }
  };

  useEffect(() => {
    setToken(Token)
    getBan(searchParams.get("page") || 1);
  }, []);


  const handleChange = (e) => {
    // Get the value from the input
    const value = e.target.value;

    // Trim both leading and trailing spaces
    const trimmedValue = value.trimStart();

    // Prevent setting the value if it's just spaces or empty
    if (trimmedValue === "") {
        setbanreason(""); // Clear the state if the value is just spaces or empty
        return;
    }

    setbanreason(trimmedValue); // Set the state with the trimmed value
};

  return (
    <>
      <div className="flex justify-between">
        <div className=" mb-4">
          <p className=" text-2xl font-semibold text-moredarkgreen">
            Banned Users List
          </p>
          {/* <p className=" opacity-80 text-sm">Home / User</p> */}
        </div>
      </div>
      <Filter emptyinput={emptyinput}/>
      <div className="card bg-base-100 shadow-md p-6 mt-8 overflow-auto">
        <div className="w-full">
          <table className="table table-zebra w-full">
            <thead>
              <tr>
                <th>S.no</th>
                <th>Name</th>
                <th>First Attempted On</th>
                <th>Last Attempted On</th>
                <th>Ban Removal date</th>
                <th>Suspended date</th>
                <th className="text-center">Violation Attempts</th>
                {/* <th>Type</th> */}
                <th>Status</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody className="text-sm relative">
              {loadBan && (
                <>
                  <tr>
                    <td colSpan={8} className="animate-pulse py-6"></td>
                  </tr>
                  <tr>
                    <td colSpan={8} className="animate-pulse py-6"></td>
                  </tr>
                  <tr>
                    <td colSpan={8} className="animate-pulse py-6"></td>
                  </tr>
                  <tr>
                    <td colSpan={8} className="animate-pulse py-6"></td>
                  </tr>
                  <tr>
                    <td colSpan={8} className="animate-pulse py-6"></td>
                  </tr>
                  <tr>
                    <td colSpan={8} className="animate-pulse py-6"></td>
                  </tr>
                </>
              )}

              {Ban &&
                Ban.map((res, index) => (
                  <tr key={index}>
                     <td> {perPage * (indexPage - 1) + (index + 1)}</td>

                    <td className=" capitalize">
                      <span className=" font-bold">
                        {res?.first_name}   {res?.last_name}
                      </span>{" "}
                      <p className="capitalize text-xs">
                      {res?.email || res?.phone_number}
                      </p>
                    </td>
                    <td className=" capitalize">
                        {res.start_violation?.start_violation_date ? 
                         <Moment format="MM/DD/YYYY">
                         {res.start_violation?.start_violation_date ?? "N/A"}
                       </Moment>
                       :
                        "N/A"}
                     
                    </td>
                    <td className=" capitalize">
                      <Moment format="MM/DD/YYYY">
                        {res.suspended_date ?? "N/A"}
                      </Moment>
                    </td>
                    <td className=" capitalize">
                        {res?.violation_attempt === 6 || res.suspended_by_admin ? 
                        "--"
                            :
                            <Moment format="MM/DD/YYYY">
                                {res.suspended_remove_date ?? "N/A"}
                            </Moment>
                            }
                      
                    </td>
                    <td className=" capitalize">
                        {res?.violation_attempt === 6 || res.suspended_by_admin ? 
                        <Moment format="MM/DD/YYYY">
                        {res.suspended_date ?? "N/A"}
                      </Moment>
                      : "--"}
                      
                    </td>
                    <td className=" capitalize">
                      <p className="capitalize text-center">
                      {res?.violation_attempt}
                      </p>
                    </td>
                    {/* <td className=" capitalize">{res?.report_type ??"--"}
               { res?.report_type == "Message" && 
                <label
                title="Message"
                htmlFor="MessageDetails"
                className={`btn capitalize text-[white] border-hidden  btn-xs ml-2`}
                onClick={(e) => 
                  setMessage(res)
                }
            
              >
                                       <BiInfoCircle />

              </label>
               }
               
                </td> */}

                    <th>
                      <label
                        title="Change Status"
                        htmlFor={res.violation_attempt !== 6 && res.suspended_by_admin !== 1 ? "my-modal-5" : ""}
                        className={`btn capitalize ${
                          res.violation_attempt === 6 || res.suspended_by_admin
                            ? "bg-[#ff0000] hover:bg-[#ff0000] text-[#fff]"
                            : "bg-warning hover:bg-warning text-[#000]"
                        }   border-hidden  btn-xs`}
                        onClick={(e) => {
                          setbanid(res?.id);
                          setbanreason("");
                        }}
                      >
                        {res?.violation_attempt === 6 || res.suspended_by_admin
                          ? "Permanently Banned"
                          : "Temporarily Banned"}
                      </label>
                    </th>
                    {/* <td></td> */}
                    <td className=" text-base">
                    <div className="flex items-center space-x-4">
                    <label
                        title="Remove Ban"
                        htmlFor={"my-modal-6"}
                        className={`btn capitalize bg-[#ff0000] hover:bg-[#ff0000] text-[#fff]  border-hidden  btn-xs`}
                        onClick={(e) => {
                          setbanid(res?.id);
                          setbanreason("");
                        }}
                      >
                        Remove Ban
                      </label>
                      <button title="Attempt history">
                        <Link to={`/banuser/${res?.id}`}>
                            <RxEyeOpen />
                        </Link>
                      </button>
                    </div>
                  </td>
                  </tr>
                ))}

              {nolist && (
                <tr>
                  <td colSpan={8} className="my-10 text-center">
                    <MdReportGmailerrorred className=" text-[#dedede] text-8xl mx-auto" />
                    <p className=" font-semibold text-lg">
                      No Banned User Found
                    </p>
                  </td>
                </tr>
              )}
            </tbody>
          </table>

          <Pagination
            activePage={activePage}
            containerClassName={"pagination"}
            itemsCountPerPage={perPage}
            totalItemsCount={Number(Count)}
            pageRangeDisplayed={10}
            onChange={handlePageChange}
          />

          {/* status change model start */}
          <input type="checkbox" id="my-modal-5" className="modal-toggle" />
          <div className="modal">
            <div className="modal-box relative">
              <label
                htmlFor="my-modal-5"
                className="btn btn-sm btn-circle absolute right-2 top-2"
              >
                ✕
              </label>
              <h3 className="text-lg font-bold text-center">
              Are you sure you want to permanently ban this user?
              </h3>
              <div className="mt-3">
                <div>
              <label
                htmlFor="Reason"
                className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2"
              >
                Reason<span className="text-[#f00]">&nbsp;*</span>
              </label>
              <textarea
                name="Reason"
                required
                placeholder="Reason"
                id="Reason"
                value={banreason}
                onChange={(e) => {
                    handleChange(e)
                }}
                autoComplete="off"
                className="textarea focus:outline-0 w-full text-sm font-medium rounded-md focus:outline-offset-0 input-bordered"
              ></textarea>
            </div>
              </div>
              <div className="modal-action justify-center">
                <label
                  onClick={() => {
                    if(banreason.length > 0){
                        setemptyinput(!emptyinput);
                        ChangeBanStatus(banid);
                    }
                    else{
                        toast.error("The reason field is required.");
                    }
                  }}
                  htmlFor={banreason.length > 0 ? "my-modal-5" : ""}
                  className="btn btn-md bg-darkgreen text-[#fff] text-sm hover:text-darkgreen hover:bg-lightgreen  ml-4"
                >
                  Yes
                </label>
                <label
                  htmlFor="my-modal-5"
                  className="btn btn-md bg-lightgreen text-darkgreen text-sm hover:bg-darkgreen hover:text-[#fff]  "
                >
                  No
                </label>
              </div>
            </div>
          </div>
          {/* status change model end */}


          {/* unbanned status change start*/}
          <input type="checkbox" id="my-modal-6" className="modal-toggle" />
          <div className="modal">
            <div className="modal-box relative">
              <label
                htmlFor="my-modal-6"
                className="btn btn-sm btn-circle absolute right-2 top-2"
              >
                ✕
              </label>
              <h3 className="text-lg font-bold text-center">
              Are you sure you want to remove ban for this user?
              </h3>
              <div className="mt-3">
                <div>
              <label
                htmlFor="Reason"
                className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2"
              >
                Reason<span className="text-[#f00]">&nbsp;*</span>
              </label>
              <textarea
                name="Reason"
                required
                placeholder="Reason"
                id="Reason"
                value={banreason}
                onChange={(e) => {
                    handleChange(e)
                }}
                autoComplete="off"
                className="textarea focus:outline-0 w-full text-sm font-medium rounded-md focus:outline-offset-0 input-bordered"
              ></textarea>
            </div>
              </div>
              <div className="modal-action justify-center">
                <label
                  onClick={() => {
                    if(banreason.length > 0){
                        setemptyinput(!emptyinput);
                        UnBanStatus(banid);
                    }
                    else{
                        toast.error("The reason field is required.");
                    }
                  }}
                  htmlFor={banreason.length > 0 ? "my-modal-6" : ""}
                  className="btn btn-md bg-darkgreen text-[#fff] text-sm hover:text-darkgreen hover:bg-lightgreen  ml-4"
                >
                  Yes
                </label>
                <label
                  htmlFor="my-modal-6"
                  className="btn btn-md bg-lightgreen text-darkgreen text-sm hover:bg-darkgreen hover:text-[#fff]  "
                >
                  No
                </label>
              </div>
            </div>
          </div>
          {/* unbanned status change end*/}
        </div>
      </div>
    </>
  );
});

export default BanUserManagement;
