import React, { useEffect } from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { MdOutlineClose, MdOutlineCheck, MdOutlineRefresh, MdOutlineSearch } from "react-icons/md";
import { Input, Button } from "react-daisyui"
import { useBanUserStore, BanUserStore, BanUserStoreContext } from "../store/BanUserStore";
import { observer } from "mobx-react";
import { useAuthStore } from "../../Auth/store/Auth";

// const Filter = observer(() => {
//     const store = new BanUserStore({
//         isloading:true,
//       });
//     return (
//         <BanUserStoreContext.Provider value={store}>
//             <Screen />
//         </BanUserStoreContext.Provider>
//     )
// })

// export default Filter;

const Filter = observer(({emptyinput}) => {
    const [searchParams] = useSearchParams();
    const searchParamPage = searchParams.get("page");
    const {Token} = useAuthStore();
    const {
        setfirst_name,
        setban_for,
        setstatus,
        setfromdate,
        settodate,
        getFilterBan,
        getBan,
        setreporttype,
        fromdate,
        todate,
        first_name,
        ban_for,
        reporttype,
        status,
        setToken
      }= useBanUserStore()

      const Reset=()=>{
        setfirst_name("")
        setban_for("")
        setstatus("")
        setfromdate("")
        settodate("")
        setreporttype("")
        getBan(searchParamPage || 1)
    }

    useEffect(() => {
        setToken(Token)
        Reset()
    },[emptyinput])

    

    return (
        <>

            <div className="card bg-base-100 shadow-md p-6 mt-3 mb-10">
                <form onSubmit={(e) => {getFilterBan(searchParamPage || 1); e.preventDefault() }}>
                    <div className="grid lg:grid-cols-3 md:grid-cols-2 md:gap-8 gap-4">
                    <div>
                            <label htmlFor="first_name" className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2">First Name</label>
                            <Input
                                name="first_name"
                                placeholder="First Name"
                                id="first_name"
                                value={first_name}
                                onChange={(e)=> {setfirst_name(e.target.value)}}
                                type="text"
                                autoComplete="off"
                                className="focus:outline-0 w-full text-sm font-medium rounded-md"
                            />
                        </div>
                        <div>
                            <label htmlFor="violation_attempt" className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2">Violation Attempted</label>
                            <select
                                id="violation_attempt"
                                name="violation_attempt"
                                onChange={(e)=> {setstatus(e.target.value)}}
                                value={status}
                                className={`select focus:outline-0 w-full text-sm font-medium rounded-md focus:outline-offset-0 select-bordered`}>
                                <option value="" className="">All</option>
                                <option value="1">1</option>
                                <option value="2">2</option>
                                <option value="3">3</option>
                                <option value="4">4</option>
                                <option value="5">5</option>
                                <option value="6">6</option>
                            </select>
                        </div>
                    </div>
                    <div className="lg:mt-3 mt-4 flex items-center md:justify-end justify-between">
                        <button type="reset" onClick={() => {Reset() }} className="btn btn-md bg-darkgreen text-[#fff] text-sm hover:text-darkgreen hover:bg-lightgreen "><MdOutlineRefresh className="mr-1" />Reset</button>
                        <button type="submit" className="btn btn-md bg-lightgreen text-darkgreen text-sm hover:bg-darkgreen hover:text-[#fff]   ml-4"><MdOutlineSearch className="mr-1" />Search</button>
                    </div>
                </form>
            </div>

        </>
    )
})
export default Filter;