import { observer } from "mobx-react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import Moment from "react-moment";
import {
  RxDashboard,
  RxEyeOpen,
  RxPencil1,
  RxPerson,
  RxPlus,
} from "react-icons/rx";
import {
  useReportedUserStore,
  ReportedUserStore,
  ReportedUserStoreContext,
} from "./store/ReportedUserStore";
import Filter from "./ReportedUserFilter/reporteduserfilter";
import { MdAdd, MdReportGmailerrorred } from "react-icons/md";
import { useEffect, useState } from "react";
import Pagination from "react-js-pagination";
import {
  BiDetail,
  BiDollarCircle,
  BiSearch,
  BiInfoCircle,
} from "react-icons/bi";
import { useAuthStore } from "../Auth/store/Auth";
// const ReportedUserManagement = () => {
//   const store = new ReportedUserStore({
//     isloading: true,
//   });
//   return (
//     <ReportedUserStoreContext.Provider value={store}>
//       <Screen />
//     </ReportedUserStoreContext.Provider>
//   );
// };

// export default ReportedUserManagement;

const ReportedUserManagement = observer(() => {
  const navigate = useNavigate();
  const {Token} = useAuthStore();
  const [reportedid, setreportedid] = useState("");
  const [emptyinput,setemptyinput] = useState(false);
  const [searchParams] = useSearchParams();
  const searchParamPage = searchParams.get("page");
  const [currentPage, setCurrentPage] = useState(searchParams.get("page") || 1);
  const {
    Reported,
    loadReported,
    nolist,
    setreportedstatus,
    ChangeReportedStatus,
    reportedstatus,
    setMessage,
    message,
    setReason,
    reason,
    activePage,
    perPage,
    Count,
    NofilterwithPagination,
    filtertrue,
    getReported,
    setActivePage,
    setIndexPage,
    filterVisibility,
    setToken,
    indexPage
  } = useReportedUserStore();

  const handlePageChange = (pageNumber) => {
    NofilterwithPagination(true);
    const element = document.getElementById("tablescroll");
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
    // if (filtertrue) {
    //   getFilterUsers(pageNumber);
    //   setIndexPage(pageNumber);
    //   setActivePage(pageNumber);
    // } else {
      if (filterVisibility === true) {
        getReported(pageNumber);
        setActivePage(pageNumber);
        setIndexPage(pageNumber);
      } else {
        setActivePage(pageNumber);
        setIndexPage(pageNumber);
      }
    // }

    /**
     * if selected page is not 1 then navigate to the selected page adn ad dpageNumber to url
     */
    if (Number(pageNumber) + 1 !== 1) {
      navigate(`?page=${Number(pageNumber)}`);
      setActivePage(Number(pageNumber));
    } else {
      navigate("");
    }
  };

  useEffect(() => {
    setToken(Token)
    getReported(searchParams.get("page") || 1);
  }, []);

  return (
    <>
      <div className="flex justify-between">
        <div className=" mb-4">
          <p className=" text-2xl font-semibold text-moredarkgreen">
            Reported users List
          </p>
          {/* <p className=" opacity-80 text-sm">Home / User</p> */}
        </div>
      </div>
      <Filter emptyinput={emptyinput}/>
      <div className="card bg-base-100 shadow-md p-6 mt-8 overflow-auto">
        <div className="w-full">
          <table className="table table-zebra w-full">
            <thead>
              <tr>
                <th>S.no</th>
                <th>Reported By</th>
                <th>Reported For</th>
                <th>Reported Date</th>
                <th>Report Type</th>
                {/* <th>Type</th> */}
                <th>Status</th>
                {/* <th>Action</th> */}
              </tr>
            </thead>
            <tbody className="text-sm relative">
              {loadReported && (
                <>
                  <tr>
                    <td colSpan={8} className="animate-pulse py-6"></td>
                  </tr>
                  <tr>
                    <td colSpan={8} className="animate-pulse py-6"></td>
                  </tr>
                  <tr>
                    <td colSpan={8} className="animate-pulse py-6"></td>
                  </tr>
                  <tr>
                    <td colSpan={8} className="animate-pulse py-6"></td>
                  </tr>
                  <tr>
                    <td colSpan={8} className="animate-pulse py-6"></td>
                  </tr>
                </>
              )}

              {Reported &&
                Reported.map((res, index) => (
                  <tr key={index}>
                     <td> {perPage * (indexPage - 1) + (index + 1)}</td>

                    <td className=" capitalize">
                      <span className=" font-bold">
                        {res?.report_user?.first_name}
                      </span>{" "}
                      <p className="capitalize text-xs">
                      {res?.report_user?.email || res?.report_user?.phone_number}
                      </p>
                    </td>
                    <td className=" capitalize">
                      <span className=" font-bold">
                        {res?.reported_user?.first_name}
                      </span>{" "}
                      <p className="capitalize text-xs">
                      {res?.reported_user?.email || res?.reported_user?.phone_number}
                      </p>
                    </td>
                    <td className=" capitalize">
                      <Moment format="MM/DD/YYYY">
                        {res.date_time ?? "N/A"}
                      </Moment>
                    </td>
                    <td className=" capitalize">
                      {res?.report_type ?? "--"}

                      <label
                        title="Details"
                        htmlFor="ReasonDetails"
                        className={`btn capitalize text-[white] border-hidden  btn-xs ml-2`}
                        onClick={(e) => {
                          setReason(res);
                          setMessage(res);
                        }}
                      >
                        <BiInfoCircle />
                      </label>
                      {/* {res?.report_reason ? <div className="tooltip"> {(`${res?.report_reason}`).substring(0, 50).concat('...') ?? "N/A"}
                    <span className="tooltiptext bg-[#000]" > {res?.report_reason}</span>
                  </div>
                  :
                  "N/A"
                  } */}
                    </td>
                    {/* <td className=" capitalize">{res?.report_type ??"--"}
               { res?.report_type == "Message" && 
                <label
                title="Message"
                htmlFor="MessageDetails"
                className={`btn capitalize text-[white] border-hidden  btn-xs ml-2`}
                onClick={(e) => 
                  setMessage(res)
                }
            
              >
                                       <BiInfoCircle />

              </label>
               }
               
                </td> */}

                    <th>
                      <label
                        title="Change Status"
                        htmlFor="my-modal-5"
                        className={`btn capitalize ${
                          res.is_block === "Unblock"
                            ? "bg-green hover:bg-green"
                            : "bg-warning hover:bg-warning"
                        }  text-[#000] border-hidden  btn-xs`}
                        onClick={(e) => {
                          setreportedid(res?.id);
                          setreportedstatus(res.is_block);
                        }}
                      >
                        {res.is_block !== "Pending" && "User Is"}{" "}
                        {res?.is_block === "Pending"
                          ? "Pending"
                          : res?.is_block === "Unblock"
                          ? "Activated"
                          : "Inactivated"}
                      </label>
                    </th>
                    {/* <td></td> */}
                    {/* <td className=" text-base">
                    <div className="flex items-center space-x-4">
                      <button title="Edit">
                        <Link to={`/ReportedUsermanagement/${1}`}>
                            <RxPencil1 />
                        </Link>
                      </button>
                    </div>
                  </td> */}
                  </tr>
                ))}

              {nolist && (
                <tr>
                  <td colSpan={8} className="my-10 text-center">
                    <MdReportGmailerrorred className=" text-[#dedede] text-8xl mx-auto" />
                    <p className=" font-semibold text-lg">
                      No Reported User Found
                    </p>
                  </td>
                </tr>
              )}
            </tbody>
          </table>

          <Pagination
            activePage={activePage}
            containerClassName={"pagination"}
            itemsCountPerPage={perPage}
            totalItemsCount={Number(Count)}
            pageRangeDisplayed={10}
            onChange={handlePageChange}
          />

          {/* message popup */}
          <>
            <input
              type="checkbox"
              id="MessageDetails"
              className="modal-toggle"
            />

            <div className="modal">
              <div className="modal-box relative">
                <label
                  htmlFor="MessageDetails"
                  className="btn btn-sm btn-circle absolute right-2 top-2"
                  // onClick={()=>setstatusVoidpopup(false)}
                >
                  ✕
                </label>
                <span className="flex justify-center font-bold text-lg text-[#2f5542]">
                  Message
                </span>
                <div className="">
                  {message?.message_type == "text" ||
                  message?.message_type == "location" ||
                  message?.message_type == "contact" ? (
                    <p className="break-all text-center">
                      {message?.message ?? "N/A"}
                    </p>
                  ) : (
                    <a
                      className="break-all text-center"
                      href={message?.message}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {" "}
                      {message?.message ?? "N/A"}{" "}
                    </a>
                  )}
                </div>
              </div>
            </div>
          </>

          {/* reason popup */}
          <>
            <input
              type="checkbox"
              id="ReasonDetails"
              className="modal-toggle"
            />

            <div className="modal">
              <div className="modal-box relative">
                <label
                  htmlFor="ReasonDetails"
                  className="btn btn-sm btn-circle absolute right-2 top-2"
                  // onClick={()=>setstatusVoidpopup(false)}
                >
                  ✕
                </label>
                {/* reason block */}
                <span className="flex justify-center font-bold text-lg text-[#2f5542]">
                  User Report Reason
                </span>
                <div className="">
                  <p className="break-all text-center">
                    {reason?.report_reason ?? "N/A"}
                  </p>
                </div>
                {/* message block */}
                {message?.report_type == "Message" && (
                  <>
                    <br />
                    <hr />
                    <br />
                    <span className="flex justify-center font-bold text-lg text-[#2f5542]">
                      Message
                    </span>
                    <div className="">
                      {message?.message_type == "text" ||
                      message?.message_type == "location" ||
                      message?.message_type == "contact" ? (
                        <p className="break-all text-center">
                          {message?.message ?? "N/A"}
                        </p>
                      ) : (
                        <a
                          className="break-all text-center"
                          href={message?.message}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {" "}
                          {message?.message ?? "N/A"}{" "}
                        </a>
                      )}
                    </div>
                  </>
                )}
              </div>
            </div>
          </>
          {/* status change model start */}
          <input type="checkbox" id="my-modal-5" className="modal-toggle" />
          <div className="modal">
            <div className="modal-box relative">
              <label
                htmlFor="my-modal-5"
                className="btn btn-sm btn-circle absolute right-2 top-2"
              >
                ✕
              </label>
              <h3 className="text-lg font-bold text-center">
                Are you sure you want to change Status?
              </h3>
              <div className="mt-3">
                {/* <label htmlFor="Status" className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2">Status</label> */}
                <select
                  id="Status"
                  name="Status"
                  required
                  value={reportedstatus}
                  onChange={(e) => setreportedstatus(e.target.value)}
                  className={`select focus:outline-0 w-full text-sm font-medium rounded-md focus:outline-offset-0 select-bordered`}
                >
                  {/* <option value="" className="">All</option> */}
                  <option value="Pending">Pending</option>
                  <option value="Block">Inactive Reported User</option>
                  <option value="Unblock">Active Reported User</option>
                </select>
                {reportedstatus === "Block" && (
                  <div className="text-[red] text-center mt-2">
                    ("All channels and groups owned by the user will be hidden.")
                  </div>
                )}
                {reportedstatus === "Unblock" && (
                  <div className="text-[red] text-center mt-2">
                    ("All channels and groups owned by the user will be visible.")
                  </div>
                )}
              </div>
              <div className="modal-action justify-center">
                <label
                  onClick={() => {
                    setemptyinput(!emptyinput);
                    ChangeReportedStatus(reportedid);
                  }}
                  htmlFor="my-modal-5"
                  className="btn btn-md bg-darkgreen text-[#fff] text-sm hover:text-darkgreen hover:bg-lightgreen  ml-4"
                >
                  Yes
                </label>
                <label
                  htmlFor="my-modal-5"
                  className="btn btn-md bg-lightgreen text-darkgreen text-sm hover:bg-darkgreen hover:text-[#fff]  "
                >
                  No
                </label>
              </div>
            </div>
          </div>
          {/* status change model end */}
        </div>
      </div>
    </>
  );
});

export default ReportedUserManagement;
