import {createContext,useContext} from 'react'
import { makeAutoObservable, runInAction } from "mobx";
import {getMethodData,putMethod, patchMethodData, postFormData} from "../../ApiMethods/api"
import { toast } from "react-toastify";
import { AES,enc } from 'crypto-js';

export class BanUserStore {
    Token = null;
    indexPage=1;
    activePage=1;
    Count=1;
    perPage="";
    filterVisibility=true;
    loadBan= false;
    errloadBan = "";
    saved= false;
    nolist=false;
    Ban=[];
    BanHistory=[];
    orderone={};

    first_name="";
    ban_for="";
    
    fromdate="";
    todate="";

    customername="";
    customeremail="";

    status="";

    banreason= ""
    message=""

    reason=""
    reporttype= ""
    nofilterwithpagination = false
    constructor(isloading = false) {
        makeAutoObservable(this);
        this.Token = localStorage.getItem("Admintoken");
        if(isloading.isloading){
            this.getBan(isloading.pagginationcoun)
            return
        }
    }
    setActivePage = (text) =>{
        this.activePage = text;
      }
      setIndexPage=(e)=>{
        this.indexPage=e;
      }
      setfiltertrue=(e)=>{
        this.filtertrue = e;
      }
      setCount=(e)=>{
        this.Count=e;
      }
      setPerPage=(e)=>{
        this.perPage=e;
      }
      setFilterVisibility=(e)=>{
        this.filterVisibility=e;
      }
    setfirst_name = (text) =>  {
        this.first_name = text;
    }
    setban_for = (text) =>  {
        this.ban_for = text;
    }
    setfromdate = (text) =>  {
        this.fromdate = text;
    }
    settodate = (text) =>  {
        this.todate = text;
    }
    setcustomername = (text) =>  {
        this.customername = text;
    }
    setcustomeremail = (text) =>  {
        this.customeremail = text;
    }
    setstatus = (text) =>  {
        this.status = text;
    }
    setreporttype = (text) =>  {
        this.reporttype = text;
    }
    setbanreason = (text) => {
        this.banreason = text;
    }

    // message popup data
    setMessage=(e)=>{
        this.message= e
      }
      setToken=(e)=>{
        this.Token=e;
      }
      // reason popup data
    setReason=(e)=>{
        this.reason= e
      }

      
      NofilterwithPagination = (e) => {
        runInAction(() => {
            this.nofilterwithpagination = e
        })
      }

    // get Ban List
    getBan = (pageNumber) => {
        runInAction(() => {
            this.loadBan = true;
            this.errloadBan = "";
            this.saved = false;
            this.indexPage = pageNumber;
            });
        getMethodData(`/admin/users/suspend-account-list?page=${pageNumber}`,this.Token)
        .then((resp) => {
            if (resp?.data?.status === false) {
                runInAction(() => {
                this.loadBan = false;
                this.errloadBan = resp?.data?.message
                });
                return;
            }
            if (resp?.data?.status === true) {
                runInAction(() => {
                this.loadBan = false;
                this.errloadBan = resp?.data?.message;
                // console.log(resp?.data?.data.length);
                if(resp?.data?.data?.data?.length === 0) {
                    this.nolist = true;
                }else{
                    this.nolist = false;
                }
                // const data = resp?.data?.data?.data.map(d => {
                //     if(d.report_type == "User"){
                //         //
                //     }
                //     else if (d.message_type == "text"){
                //         d.message = AES.decrypt(d.message,process.env.REACT_APP_ENCRYPTION_KEY).toString(enc.Utf8)
                //     }
                //     else if (d.message !== null ){ 
                //         d.message = JSON.parse(d.message)[0];
                //     }
                //     return d;
                // })
                this.setCount(resp.data.data.total);
                this.setPerPage(resp.data.data.per_page);
                this.setActivePage(resp.data.data.current_page)
                this.filterVisibility = true;
                this.Ban = resp?.data?.data?.data;
                this.saved = false;
                // this.status = true;
                });
                return;
            }
            runInAction(() => {
                this.loadBan = false;
                this.errloadBan = resp?.data?.message;
            });
            })
            .catch((error) => {
            runInAction(() => {
                this.loadBan = false;
                if(!error.response){
                    this.errloadBan = error?.message;
                    toast.error(error?.message);
                }
                this.errloadBan = error?.response?.data?.message;
                toast.error(error?.response?.data?.message);
            })
            return;
        });
    };

    getBanHistory = (pageNumber) => {
      runInAction(() => {
          // this.loadBan = true;
          this.errloadBan = "";
          // this.saved = false;
          this.indexPage = pageNumber;
          });
      getMethodData(`/admin/users/account-suspend-history?user_id=${pageNumber}`,this.Token)
      .then((resp) => {
          if (resp?.data?.status === false) {
              runInAction(() => {
              // this.loadBan = false;
              this.errloadBan = resp?.data?.message
              });
              return;
          }
          if (resp?.data?.status === true) {
              runInAction(() => {
              this.loadBan = false;
              this.errloadBan = resp?.data?.message;
              // console.log(resp?.data?.data.length);
              if(resp?.data?.data?.data?.length === 0) {
                  this.nolist = true;
              }else{
                  this.nolist = false;
              }
              // const data = resp?.data?.data?.data.map(d => {
              //     if(d.report_type == "User"){
              //         //
              //     }
              //     else if (d.message_type == "text"){
              //         d.message = AES.decrypt(d.message,process.env.REACT_APP_ENCRYPTION_KEY).toString(enc.Utf8)
              //     }
              //     else if (d.message !== null ){ 
              //         d.message = JSON.parse(d.message)[0];
              //     }
              //     return d;
              // })
              // this.setCount(resp.data.data.total);
              // this.setPerPage(resp.data.data.per_page);
              // this.setActivePage(resp.data.data.current_page)
              // this.filterVisibility = true;
              this.BanHistory = resp?.data?.data?.data;
              // this.saved = false;
              // this.status = true;
              });
              return;
          }
          runInAction(() => {
              this.loadBan = false;
              this.errloadBan = resp?.data?.message;
          });
          })
          .catch((error) => {
          runInAction(() => {
              this.loadBan = false;
              if(!error.response){
                  this.errloadBan = error?.message;
                  toast.error(error?.message);
              }
              this.errloadBan = error?.response?.data?.message;
              toast.error(error?.response?.data?.message);
          })
          return;
      });
  };

        //filter Ban api 

        getFilterBan = (page) => {
            runInAction(() => {
                this.loadBan = true;
                this.errloadBan = "";
                this.saved = false;
                this.indexPage = page;
              });
            getMethodData(`/admin/users/suspend-account-list?page=${page}&first_name=${this.first_name}&violation_attempt=${this.status}`,this.Token)
            .then((resp) => {
      
                if (resp?.data?.status === false) {
                  runInAction(() => {
                    this.loadBan = false;
                    this.errloadBan = resp?.data?.message
                  });
                  return;
                }
                if (resp?.data?.status === true) {
                    runInAction(() => {
                        this.loadBan = false;
                        this.errloadBan = resp?.data?.message;
                        // console.log(resp?.data?.data.length);
                        if(resp?.data?.data?.data?.length === 0) {
                            this.nolist = true;
                        }else{
                            this.nolist = false;
                        }
                        // console.log(resp?.data?.data?.data)
                        this.Ban = resp?.data?.data?.data;
                        this.saved = false;
                        this.setCount(resp.data.data.total);
                        this.setPerPage(resp.data.data.per_page);
                        this.setActivePage(resp.data.data.current_page)
                        this.filterVisibility = true;
                        // this.status = true;
                        });
                  return;
                }
                runInAction(() => {
                  this.loadBan = false;
                  this.errloadBan = resp?.data?.message;
                });
              })
              .catch((error) => {
                runInAction(() => {
                    this.loadBan = false;
                    if(!error.response){
                        this.errloadBan = error?.message;
                        toast.error(error?.message);
                    }
                    this.errloadBan = error?.response?.data?.message;
                    toast.error(error?.response?.data?.message);
                })
                return;
            });
        };


// ban user status change

        ChangeBanStatus = (id) => {
            runInAction(() => {
              this.loadBan = true;
              this.errloadBan = "";
            });
            const formData = new FormData();
            formData.append("reason", this.banreason);
            formData.append("attempt", 6);
            formData.append("user_id", id);
         
            postFormData(`/admin/users/update-violation-attempt`,formData,this.Token)
              .then((resp) => {
                if (resp?.data?.status === false) {
                  runInAction(() => {
                    this.loadBan = false;
                    this.errloadBan = resp?.data?.message;
                    toast.error(resp.data?.data?.errors?.name[0]);
                  });
                  return;
                }
                if (resp?.data?.status === true) {
                  runInAction(() => {
                      this.loadBan = false;
                      this.errloadBan = resp?.data?.message;
                      toast.success(resp.data.message);
                      //  this.getBan(1);
                        // this.saved = true;
                    })
                    return
                }
                runInAction(() => {
                  this.loadBan = false;
                  this.errloadBan = resp?.data?.message;
                  toast.error(resp.data.message);
                });
              })
              .catch((error) => {
                
                runInAction(() => {
                    if(!error.response){
                      this.loadBan = false;
                      this.errloadBan = error?.message;
                      toast.error(error?.message);
                    }
                      toast.error(error?.response?.data?.message);
                      this.loadBan = false;
                      this.errloadBan = error?.response?.data?.message;
                })
                return;
            })
          };

          UnBanStatus = (id) => {
            runInAction(() => {
              this.loadBan = true;
              this.errloadBan = "";
            });
            const formData = new FormData();
            formData.append("reason", this.banreason);
            formData.append("user_id", id);
         
            postFormData(`/admin/users/account-unsuspend`,formData,this.Token)
              .then((resp) => {
                if (resp?.data?.status === false) {
                  runInAction(() => {
                    this.loadBan = false;
                    this.errloadBan = resp?.data?.message;
                    toast.error(resp.data?.data?.errors?.name[0]);
                  });
                  return;
                }
                if (resp?.data?.status === true) {
                  runInAction(() => {
                      this.loadBan = false;
                      this.errloadBan = resp?.data?.message;
                      toast.success(resp.data.message);
                      //  this.getBan(1);
                        // this.saved = true;
                    })
                    return
                }
                runInAction(() => {
                  this.loadBan = false;
                  this.errloadBan = resp?.data?.message;
                  toast.error(resp.data.message);
                });
              })
              .catch((error) => {
                
                runInAction(() => {
                    if(!error.response){
                      this.loadBan = false;
                      this.errloadBan = error?.message;
                      toast.error(error?.message);
                    }
                      toast.error(error?.response?.data?.message);
                      this.loadBan = false;
                      this.errloadBan = error?.response?.data?.message;
                })
                return;
            })
          };
};


export const BanUserStoreContext = createContext(new BanUserStore());
export const useBanUserStore = () => useContext(BanUserStoreContext);